<template>
  <div class="page">
    <van-nav-bar title="明细记录" left-arrow @click-left="back()" />
    <div class="sift">
      <van-field
        v-model="condition.time"
        is-link
        readonly
        name="datetimePicker"
        label="时间选择"
        placeholder="点击选择时间"
        @click="showTime = true"
      />
      <van-popup :show="showTime" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onTimeConfirm"
          @cancel="onTimeCancel"
        />
      </van-popup>
    </div>
    <div class="title"><van-icon name="notes-o" /> 收支记录</div>
    <van-list
      :finished="finishedShow"
      finished-text="没有更多了"
      offset="0"
      class="record"
      @load="loadTaskList"
    >
      <template #default>
        <van-row class="item" v-for="record in recordList" :key="record">
          <van-col span="4">
            <svg
              class="icon"
              aria-hidden="true"
              v-if="
                record.operate === 'SHR' ||
                record.operate === 'ICM' ||
                record.operate === 'RHE'
              "
            >
              <use xlink:href="#icon-income"></use>
            </svg>
            <svg class="icon" aria-hidden="true" v-else>
              <use xlink:href="#icon-disburse"></use></svg
          ></van-col>
          <van-col span="14"
            ><div class="desc">{{ record.description }}</div>
            <div class="time">{{ record.time }}</div>
          </van-col>
          <van-col span="6"
            ><span
              class="amount color-s1"
              v-if="
                record.operate === 'SHR' ||
                record.operate === 'ICM' ||
                record.operate === 'RHE'
              "
              >+ {{ record.amount }}元</span
            ><span class="amount color-m" v-else
              >- {{ record.amount }}元</span
            ></van-col
          >
        </van-row>
        <van-row v-if="recordList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row> </template
    ></van-list>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Customer from './Customer.js'
export default {
  ...Customer
}
</script>
<style lang="less" scoped>
@import './Customer.less';
</style>
